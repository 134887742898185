import * as React from "react"

import PrivateRoute from "../../../components/privateRoute"
import GroupList from "../../../components/Contacts/Groups/Listing/list"
import ReduxWrapper from "../../../redux/reduxWrapper"

const Groups = props => {
  let searchUrl = props.location.search
  let addGroup = searchUrl.indexOf("addGroup") === 1

  return <PrivateRoute component={GroupList} location={props.location} addGroup={addGroup} />
}

const WrappedPage = props => <ReduxWrapper element={<Groups {...props} />} />
export default WrappedPage

export const Head = () => <title>Contact Group Listing</title>
