import * as React from "react"
import { useRef, useState } from "react"
import clsx from "clsx"
import { Link } from "gatsby"

import Layout from "../../../Layout/layout"
import GroupLazyLoad from "./groupLazyLoad"
import GroupListRow from "./groupListRow"
import ContactListTagSortModal from "./../../Listing/contactListTagSortModal"
import NewGroupModal from "./newGroupModal"
import { isMobileView } from "../../../../services/globalHelpers"

import "../../../../css/jquery.dataTables.min.css"
import "./contactGroups.css"
import AxiosInstance from "../../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const ContactGroupList = ({ addGroup }) => {
  const [deleteGroupRow, setDeleteGroupRow] = useState(null)
  const triggerRef = useRef(null)

  const deleteGroup = (groupId, data) => {
    return new Promise(async resolve => {
      let success = false
      await AxiosInstance.delete(`/contact/group/${groupId}`).then(response => {
        if (response.data) {
          success = response.data.success
        }
      })

      resolve(data, success)
    })
  }

  const onGrabData = (currentPage, pages_count) => {
    return new Promise(async resolve => {
      let data = {
        items: [],
        pages_count: pages_count,
      }

      if (pages_count === null || currentPage <= pages_count) {
        data = await AxiosInstance.get(`/contact/group`, {
          params: {
            page: currentPage,
          },
        }).then(response => {
          return response.data
        })
      }
      resolve(data, pages_count)
    })
  }

  const { data, loading } = GroupLazyLoad({
    triggerRef,
    onGrabData,
    deleteGroup,
    deleteGroupRow,
    setDeleteGroupRow,
  })

  return (
    <Layout>
      <div id="right-section" className="h-100 contactGroupList">
        <div className="row g-0">
          <div className="col" id="page-urls">
            <div className="row tabularMenuContainer">
              <div className="col">
                <div className="input-group filterDropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-bullseye" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <span className="dropdown-item-text">== Saved Filtered Views ==</span>
                    </li>
                    <li>
                      <a className="dropdown-item createNewFilterView" href="#">
                        <i className="bi bi-plus" /> Create New Filtered View
                      </a>
                    </li>
                  </ul>
                  <input type="search" className="form-control tableSearch" placeholder="Search" />
                </div>
              </div>
              <div className="col-sm-8">
                <div className="float-end">
                  <ul className="nav nav-tabs swiftCloudTabs d-none d-md-flex" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />
                          <span className="d-none d-md-inline">Add New</span>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li>
                            <Link to={"/contacts/add"} className="dropdown-item">
                              <i className="bi bi-person-plus-fill" /> Contact
                            </Link>
                          </li>

                          {!isMobileView() && <NewGroupModal addGroup={addGroup} />}

                          <li>
                            <a className="dropdown-item">
                              <i className="bi bi-table" /> Upload CSV
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item" role="presentation">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Contacts</Tooltip>}>
                        <Link to={"/contacts"} className="nav-link">
                          <i className="bi bi-person-fill" />
                        </Link>
                      </OverlayTrigger>
                    </li>

                    <li className="nav-item" role="presentation">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Groups & Audiences</Tooltip>}>
                        <Link to={"/contacts/groups"} className="nav-link active">
                          <i className="bi bi-people-fill" />
                        </Link>
                      </OverlayTrigger>
                    </li>
                    <li className="nav-item nav-item-trash" role="presentation">
                      <OverlayTrigger placement="top" overlay={<Tooltip>Trash</Tooltip>}>
                        <Link to={"/contacts/groups"} className="nav-link">
                          <i className="bi bi-trash-fill" />
                        </Link>
                      </OverlayTrigger>
                    </li>
                  </ul>

                  <ul className="nav nav-tabs swiftCloudTabs mobileTabs d-md-none d-flex mb-1" role="tablist">
                    <li className="nav-item">
                      <div className="btn-group btnAddNewDropdown" role="group">
                        <button
                          id="btnGroupDrop2"
                          type="button"
                          className="btn btn-primary dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-plus-lg" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop2">
                          <li>
                            <Link to={"/contacts/add"} className="dropdown-item">
                              <i className="bi bi-person-plus-fill" /> Contact
                            </Link>
                          </li>
                          {isMobileView() && <NewGroupModal addGroup={addGroup} />}
                          <li>
                            <a className="dropdown-item">
                              <i className="bi bi-table" /> Upload CSV
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="btn-group btnDriveView" role="group">
                        <button
                          id="btnGroupDrop3"
                          type="button"
                          className="btn btn-default dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-people-fill" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop3">
                          <li>
                            <Link to={"/contacts"} className="dropdown-item">
                              <i className="bi bi-person-fill" /> Contacts
                            </Link>
                          </li>

                          <li>
                            <Link to={"/contacts/groups"} className="dropdown-item">
                              <i className="bi bi-people-fill" /> Groups & Audiences
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item" href="#">
                              <i className="bi bi-trash-fill" /> Trash
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 tableFadeOut">
                <div className="tab-content" id="contactListTabs">
                  <div
                    className="tab-pane fade show active"
                    id="contacts-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contacts-tab"
                    tabIndex="0"
                  >
                    <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th width="5%">&nbsp;</th>
                          <th width="60%">
                            Group Name
                            <ContactListTagSortModal />
                          </th>
                          <th width="10%" className="d-none d-sm-table-cell">
                            # of People
                          </th>
                          <th width="9%" className="d-none d-md-table-cell">
                            <OverlayTrigger placement="top" overlay={<Tooltip>Average Rave Score</Tooltip>}>
                              <i className="bi bi-person-circle" />
                            </OverlayTrigger>
                          </th>
                          <th width="16%" className="d-none d-md-table-cell">
                            Last Activity
                          </th>
                        </tr>
                        <tr className="d-none table_action_container">
                          <td colSpan="4" className="text-left">
                            <div className="batch-action-container">
                              <button className="btn btn-trash">
                                <i className="bi bi-trash-fill" /> Move to Trash
                              </button>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((group, index) => {
                          return (
                            <GroupListRow
                              group={group}
                              key={`contact_group_row_${index}`}
                              setDeleteGroupRow={setDeleteGroupRow}
                            />
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contacts-trash-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex="0"
                  >
                    <table id="tblTrashContacts" className="table dataTable" cellSpacing="0" width="100%">
                      <thead>
                        <tr>
                          <th width="5%">&nbsp;</th>
                          <th width="70%">
                            <div className="btn-group btnAddNewDropdown filterContactDropdown" role="group">
                              <button
                                type="button"
                                className="btn btn-secondary dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="bi bi-bullseye"></i>{" "}
                                <span className="d-none d-md-inline">Everyone</span>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-people-fill"></i> Local Friends
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-person-fill"></i> People
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-building"></i> Companies
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-person-check-fill"></i> Following
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    <i className="bi bi-people-fill"></i> Tribes
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </th>
                          <th width="20%" className="d-none d-md-table-cell">
                            Last Activity
                          </th>
                          <th width="5%" className="text-end d-none d-lg-table-cell"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID1" className="css-checkbox" />
                              <label htmlFor="TC_ID1" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/Rosa.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="contact_detail.html">Rosa</a>
                            <span className="contactListCompany">SwiftCloud</span>
                            <div className="contactListQuickTools">
                              <a
                                className="contactEmail"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="rosa@domain.com"
                              >
                                <i className="bi bi-envelope-fill"></i>
                              </a>
                              <a
                                className="contactPhoneSMS"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="SMS to 9876543210"
                              >
                                <i className="bi bi-phone-fill"></i>
                              </a>
                              <a
                                className="contactPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="contactAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                            <div className="SwiftCloudTableTags">
                              <ul>
                                <li>
                                  <a href="#" data-tagname="Support">
                                    Support
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-01"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="tableColChkDel">
                            <div className="tableColChkDelBox">
                              <input type="checkbox" name="fileid[]" id="TC_ID2" className="css-checkbox" />
                              <label htmlFor="TC_ID2" className="css-checkbox-label"></label>
                              <span className="tableColChkSpan btn-secondary">
                                <img src={`${process.env.GATSBY_WRANGLER_URL}StaticImages/susan.jpg`} />
                              </span>
                            </div>
                          </td>
                          <td className="nowrap">
                            <a href="contact_detail.html">Susan</a>
                            <div className="contactListQuickTools">
                              <a
                                className="contactPhoneCall"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="Call to 9876543210"
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </a>
                              <a
                                className="contactAddress"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-placement="top"
                                title="New street"
                              >
                                <i className="bi bi-geo-alt-fill"></i>
                              </a>
                            </div>
                          </td>
                          <td className="d-none d-md-table-cell">
                            <time className="timeago" dateTime="2022-05-15"></time>
                          </td>
                          <td className="text-end tableColAction d-none d-lg-table-cell">
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              data-placement="bottom"
                              data-animation="false"
                              title="Delete"
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={triggerRef} className={clsx("trigger", { visible: loading })} />
    </Layout>
  )
}

export default ContactGroupList
