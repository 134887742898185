import * as React from "react"
import { useEffect, useReducer, useCallback } from "react"
import debounce from "lodash/debounce"

const INTERSECTION_THRESHOLD = 5
const LOAD_DELAY_MS = 100

const reducer = (state, action) => {
  switch (action.type) {
    case "set": {
      return {
        ...state,
        ...action.payload,
      }
    }
    case "onGrabData": {
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action.payload.data],
        currentPage: action.payload.data.length === 0 ? state.currentPage : state.currentPage + 1,
        pages_count: action.payload.pages_count,
      }
    }
    case "deleteContactGroupIndex": {
      console.log("action.payload.deleteContactId")
      console.log(action.payload.deleteContactId)
      let data = action.payload.stateData.filter(contact => contact.id !== action.payload.deleteContactId)

      return {
        ...state,
        data: [...data],
      }
    }
    default:
      return state
  }
}

const ContactLazyLoad = ({
  triggerRef,
  onGrabData,
  deleteGroup,
  deleteGroupRow,
  setDeleteGroupRow,
  options,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    currentPage: 1,
    pages_count: null,
    data: [],
  })

  const _handleEntry = async entry => {
    const boundingRect = entry.boundingClientRect
    const intersectionRect = entry.intersectionRect
    let deleteContactId = deleteGroupRow

    if (
      !state.loading &&
      entry.isIntersecting &&
      intersectionRect.bottom - boundingRect.bottom <= INTERSECTION_THRESHOLD &&
      !deleteGroupRow
    ) {
      dispatch({ type: "set", payload: { loading: true } })
      const responseData = await onGrabData(state.currentPage, state.pages_count)
      const data = responseData.items
      const pages_count = responseData.pages_count

      dispatch({ type: "onGrabData", payload: { data, pages_count } })
    }

    if (deleteGroupRow) {
      setDeleteGroupRow(null)
      const responseData = await deleteGroup(deleteContactId, state.data)
      let stateData = [...responseData]
      dispatch({ type: "deleteContactGroupIndex", payload: { stateData, deleteContactId } })
    }
  }
  const handleEntry = debounce(_handleEntry, LOAD_DELAY_MS)

  const onIntersect = useCallback(
    entries => {
      handleEntry(entries[0])
    },
    [handleEntry]
  )

  useEffect(() => {
    if (triggerRef.current) {
      const container = triggerRef.current
      const observer = new IntersectionObserver(onIntersect, options)

      observer.observe(container)

      return () => {
        observer.disconnect()
      }
    }
  }, [triggerRef, onIntersect, options])

  return state
}

export default ContactLazyLoad
