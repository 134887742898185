import * as React from "react"
import { Link } from "gatsby"
import TimeAgo from "timeago-react"
import { timeFormatter } from "../../../../services/timeHelper"
import TagListView from "../../../Global/Tags/tagListView"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const GroupListRow = ({ group, setDeleteGroupRow }) => {
  const groupId = "CG_ID" + group.id

  return (
    <tr>
      <td className="text-center tableColChkDel">
        <div className="tableColChkDelBox">
          <input id={groupId} type="checkbox" name="fileid[]" className="css-checkbox" />
          <label htmlFor={groupId} className="css-checkbox-label" />
          <span className="tableColChkSpan">
            <i className="bi bi-people-fill" />
          </span>
        </div>
      </td>
      <td className="nowrap">
        <Link to={`detail/${group.id}`}>{group.name}</Link>&nbsp;
        {group.tags.length > 0 && <TagListView tags={group.tags} />}
      </td>
      <td>{group.totalRecords}</td>
      <td>{group.avgRaveScore}</td>
      <td className="d-none d-md-table-cell">
        <div className="lastActivityBox">
          <TimeAgo datetime={timeFormatter(group.updatedAt)} />
        </div>
        <div className="statusActionBox">
          <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
            <a
              className="btn btn-delete"
              data-toggle="tooltip"
              data-placement="bottom"
              data-animation="false"
              title=""
              onClick={() => {
                setDeleteGroupRow(group.id)
              }}
            >
              <i className="bi bi-trash-fill" />
            </a>
          </OverlayTrigger>
        </div>
      </td>
    </tr>
  )
}

export default GroupListRow
